

.footer-background {
  position: bottom;
  min-height: 10vh;
  background-size: cover;
  background-repeat: no-repeat;
  bottom: 0;
  overflow-x: hidden;
}

.minimum-height {
  min-height: 80vh;
  overflow-x: hidden;
}

.capabilities {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.lead {
  font-size: 1.55rem;
  font-weight: 400;
}

.capability {
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-basis: calc(24% - 20px);
}

.capability img {
  max-width: 10rem;
  height: auto;
}

/* Custom Bootstrap button with outline color #4199a5 */
.btn-outline-custom {
  color: #fff;
  border-color: #4199a5;
  border-width: 3px;
  font-weight: bold;
  background-color: #4199a5;
}

.btn-outline-custom:hover {
  color: #fff;
  background-color: #4199a5;
  border-color: #4199a5;
  transform: scale(1.05);
}
.btn-outline-custom:active,
.btn-outline-custom.active {
  color: #fff;
  background-color: #4199a5;
  border-color: #4199a5;
}

a {
  color: inherit; /* This will make the link have the same color as its parent element */
  text-decoration: none; /* This will remove the underline */
}

.blog-posts-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.blog-posts-heading {
  font-size: 32px;
  margin-bottom: 20px;
}

.blog-posts-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
}

.blog-post-link {
  text-decoration: none;
  color: #000;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.blog-post-link:hover {
  transform: translateY(-5px);
}

.blog-post-markdown {
  padding: 20px;
}

.NavBar-list-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 0.3rem;
  padding: 0;
}

.NavBar-list-items:hover {
  cursor: pointer;
}

.customer-logo {
  max-width: 60%;
  height: auto;
  max-height: 60%;
  margin: 0 auto;
  display: block;
  opacity: 0.5; /* added opacity */
}

.project-section {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 540px);
  align-items: stretch;
  height: 500px;
  width: 100%; /* Ensure it takes up the full width of its container */
  margin-top: 3rem;
  margin-bottom: 3rem;
  /* Add bottom shadow */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0; /* Remove any default padding */
  box-sizing: border-box; /* Ensure padding and border are included in the total width */
  border-radius: 10px; /* Rounded corners */

}
.carousel-control-next,
.carousel-control-prev {
  filter: invert(100%);
  top: 50%;
  z-index: 5;
  display: inline-block;
  transform: translateY(100%);
}


.carousel-control.right,
.carousel-control.left,
.carousel .item,
.carousel-inner,
.carousel-inner > .item > img {
  border-radius: 10px;
}

/* Ensure carousel indicators are visible on all screen sizes */
.carousel-indicators {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #000; /* Indicator color */
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.6s ease;
}

.carousel-indicators .active {
  background-color: #fff; /* Active indicator color */
}

@media (min-width: 768px) {
  /* Adjust the size and position of indicators for larger screens if needed */
  .carousel-indicators {
    bottom: 30px;
  }

}


.project-image {
  grid-column: 1;
  background-size: cover;
  background-position: center;
  min-width: 100px;
  border-radius: 10px 0 0 10px; /* Rounded corners on the left side */

}

.project-content {
  grid-column: 2;
  display: flex;
  align-items: center;
  padding: 20px;
  font-size: 24px;
  font-family: 'Helvetica Neue', sans-serif;
  border-radius: 0 10px 10px 0; /* Rounded corners on the right side */
}

.project-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.project-button:hover {
  background-color: #ccc;
}

@media (max-width: 768px) {
  .project-section {
    grid-template-columns: 1fr;
    height: auto;
 
  }

  .project-image {
    grid-column: 1;
    grid-row: 1;
    min-height: 300px;
  }

  .project-content {
    grid-column: 1;
    grid-row: 2;
    padding: 15px;
    font-size: 14px;
  }

  .project-button {
 display: none;

  }
}

.project-info {
  flex: 1;
}



.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 320px;
  width: 320px; /* Set the same width as height to ensure a circle */
  border-radius: 50%;
  overflow: hidden; /* Hide any part of the image that exceeds the container */
}

.employee-image {
  max-width: 100%; /* Ensure the image doesn't overflow its container */
  height: auto; /* Maintain the image's aspect ratio */
  border-radius: 50%;
  display: block; /* To center the image using margin */
}

/* Adjust image size for mobile */
@media (max-width: 767px) {
  .employee-image {
    width: 200px;
    height: 200px;
  }
}

.employee-name {
  font-weight: bold;
  font-size: 1.5em;
}

.employee-position {
  display: block;
  font-size: 1.2em;
  font-weight: bold;
}

.employee-email {
  color: #666;
  font-size: 1.1em;
}

.employee-details p {
  font-size: 1.1em;
  text-align: left;
}

.mb-3 {
  margin-bottom: 5rem !important;
}

.footer-btn {
  color: black; /* Set the initial text color to white */
  transition: font-size 0.3s ease; /* Optional: smooth transition for font size change */
}

.footer-btn:hover {
  font-size: 1.1em; /* Increase the font size on hover */
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated-fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.section-card {
  position: relative;
  border: 1px solid #285f65;
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #285f65;
  color: white;
  transition: border-color 0.3s;
}

.section-card h3 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.section-card p {
  line-height: 1.5;
  font-size: 1.1rem;
}

.section-card::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -20px; /* Position arrow */
  margin-top: -10px; /* Center the arrow vertically */
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent white;
  display: none; /* Initially hidden */
}

.section-card.active {
  background-color: white;
  color: #285f65 !important;
 
}

.section-card.active::after {
  display: block; /* Show arrow for the active card */
  border-color: transparent transparent transparent #285f65; /* Arrow matches the border color */
}

.btn-primary {
  background-color: #285f65 !important;
  color: white !important;
  padding: 0.5rem 1rem !important;
  border-radius: 0.5rem !important;
  text-decoration: none !important;
  border-color: #285f65 !important;

}

.btn-primary-outline {
  background-color: transparent !important;
  color: #285f65 !important;
  padding: 0.5rem 1rem !important;
  border-radius: 0.5rem !important;
  text-decoration: none !important;
  border-color: #285f65 !important;
}

.logo-reel-wrapper {
  overflow: hidden;
  width: 100%;
}

.logo-reel {
  display: flex;
  align-items: center;
  animation: scroll 60s linear infinite;
  width: fit-content;
}

.logo-container {
  flex: 0 0 auto;
  width: 200px; /* Adjust this value to fit your logo size */
  max-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
.navbar {
  position: absolute;
  width: 100%;
  z-index: 9000;
}
.hero-image-container {
  position: relative;
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hero-image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/assets/heroImage/heroimage_coast.webp'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  width: 100%;
}

.herotext .rotating-container {
  display: inline-block;
  width: 500px; /* Adjust based on the maximum word length */
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.herotext .rotating-word {
  display: inline-block;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.herotext .rotating-word.exit {
  opacity: 0;
  transform: translateY(20px);
}

.herotext .rotating-word.enter {
  opacity: 1;
  transform: translateY(0);
}

@media (max-height: 960px) {
  .hero-content {
    margin-top: 10rem;
  }
  .herotext {
    font-size: 2.5rem;
    
  }
}

/* Adjust hero text and button for responsiveness */
@media (max-width: 991px) {
  .hero-content {
    margin-top: 10rem;
  }

  .herotext {
    font-size: 2.5rem;
    
  }

  .hero-content .col-md-6 {
    margin-bottom: 0rem;
    padding-bottom: 10%;
    justify-content: center;
    vertical-align: middle;
    
  }
}

@media (max-width: 767px) {
  .hero-content {
    margin-top: 15rem;
   
  }

  .herotext {
    font-size: 2rem;
  }

  .hero-content .col-md-6 {
    width: 100%;
    margin-bottom: 0rem;
    padding-bottom: 30%;
    height: 20vh;
  }
}



/* Hide other navbar items in collapsed state */
@media (max-width: 991px) {
  .navbar-nav {
    display: none !important;
  }
  /* Ensure the change language button is always visible */
.language-toggle-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1050;
}

}

/* Ensure the change language button is always visible */
.d-flex.justify-content-end {
  width: auto;
}


